import axios from '@/common/axios'
import qs from 'qs'

export function addSampleRequisition (data) {
  return axios({
    method: 'post',
    url: '/sale/sampleRequisition/add',
    data: qs.stringify(data)
  })
}

export function deleteSampleRequisition (id) {
  return axios({
    method: 'delete',
    url: '/sale/sampleRequisition/delete/' + id
  })
}

export function updateSampleRequisition (data) {
  return axios({
    method: 'put',
    url: '/sale/sampleRequisition/update',
    data: qs.stringify(data)
  })
}

export function selectSampleRequisitionInfo (id) {
  return axios({
    method: 'get',
    url: '/sale/sampleRequisition/info/' + id
  })
}

export function selectSampleRequisitionList (query) {
  return axios({
    method: 'get',
    url: '/sale/sampleRequisition/list',
    params: query
  })
}

export function adminSampleRequisition (data) {
  return axios({
    method: 'put',
    url: '/sale/sampleRequisition/warehouseManager',
    data: qs.stringify(data)
  })
}

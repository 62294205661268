<template>
  <div class="printBodyTotal">
    <kr-print-designer
      :temp-value="value"
      :widget-options="widgets"
      :lodop-license="licenseInfo"
      @save="handleSave"
      class="printBody"
    />
  </div>
</template>
<script>
import { selectSampleRequisitionInfo } from "@/api/sale/sampleRequisition";
export default {
  data() {
    return {
      value: {
        title: "出库单",
        width: 750,
        height: 650,
        pageWidth: 750,
        pageHeight: 650,
        tempItems: [
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 770,
            height: 25,
            left: 0,
            top: 10,
            title: "公司名称",
            value: "绍兴上虞新越医疗器械有限公司样品领用单 ",
            defaultValue: "绍兴上虞新越医疗器械有限公司样品领用单",
            name: "",
            style: {
              FontSize: 15,
              Bold: true,
              Alignment: "center",
            },
            uuid: "7f7b0b45da",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 50,
            title: "申请日期",
            value: "申请日期：",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a141",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 135,
            top: 50,
            title: "申请日期",
            value: "{ 申请日期 } ",
            defaultValue: "",
            name: "applyDate",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a142",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 275,
            top: 50,
            title: "收费情况",
            value: "收费情况： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a143",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 335,
            top: 50,
            title: "收费情况",
            value: "{收费情况} ",
            defaultValue: "fee",
            name: "fee",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a244",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 455,
            top: 50,
            title: "新客户",
            value: "新客户： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a172",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 495,
            top: 50,
            title: "新客户",
            value: "{新客户} ",
            defaultValue: "newCustomer",
            name: "newCustomer",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a144",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 80,
            title: "客户名称",
            value: "客户名称： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a145",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 400,
            height: 20,
            left: 130,
            top: 80,
            title: "客户名称",
            value: "{客户名称} ",
            defaultValue: "customerName",
            name: "customerName",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a156",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 110,
            title: "详细地址",
            value: "详细地址： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a146",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 130,
            top: 110,
            title: "详细地址",
            value: "{详细地址} ",
            defaultValue: "详细地址",
            name: "address",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a147",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 140,
            title: "联系人",
            value: "联系人：",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a141",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 120,
            top: 140,
            title: "联系人",
            value: "{ 联系人 } ",
            defaultValue: "",
            name: "contactPerson",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a142",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 275,
            top: 140,
            title: "联系电话",
            value: "联系电话： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a143",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 335,
            top: 140,
            title: "联系电话",
            value: "{联系电话} ",
            defaultValue: "telephone",
            name: "telephone",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a244",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 455,
            top: 140,
            title: "发货期限",
            value: "发货期限： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a172",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 510,
            top: 140,
            title: "发货期限",
            value: "{发货期限} ",
            defaultValue: "deliveryPeriod",
            name: "deliveryPeriod",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a144",
          },
          {
            type: "braid-table",
            title: "产品明细",
            isEdit: 0,
            dragable: true,
            resizable: true,
            width: 755,
            height: 500,
            left: 5,
            top: 176,
            title: "出库商品明细",
            value: "{ details } ",
            defaultValue: [
              {
                productName: "苹果ipone11pro",
                skuName: "iphone11pro256g",
                specModel: "165L",
                quantity: 3,
                snCode: "[SPP1Y79G1]",
                price: "23.00",
              },
              {
                productName: "苹果ipone11pro",
                skuName: "iphone11pro124g",
                specModel: "165L",
                quantity: 3,
                snCode: "[SPP1Y79G1]",
                price: "12.00",
              },
            ],
            tabelHtml: "",
            columnsAttr: [
              {
                title: "产品编号",
                value: "{产品编号}",
                name: "productNo",
              },
              {
                title: "样品名称",
                value: "{样品名称}",
                name: "sampleName",
              },
              {
                title: "规格型号",
                value: "{规格型号}",
                name: "spec",
              },
              {
                title: "计划领用数量",
                value: "{计划领用数量}",
                name: "quantity",
              },
              {
                title: "备注",
                value: "{备注}",
                name: "remarks",
              },
            ],
            selectCol: [
              "amount",
              "batchNo",
              "expirationDate",
              "orderNo",
              "productName",
              "quantity",
              "spec",
              "unit",
              "unitPrice",
              "productionLicense",
              "productRegistration",
            ],
            columns: [
              {
                title: "产品编号",
                value: "{产品编号}",
                name: "productNo",
              },
              {
                title: "样品名称",
                value: "{样品名称}",
                name: "sampleName",
              },
              {
                title: "规格型号",
                value: "{规格型号}",
                name: "spec",
              },
              {
                title: "计划领用数量",
                value: "{计划领用数量}",
                name: "quantity",
              },
              {
                title: "备注",
                value: "{备注}",
                name: "remarks",
              },
            ],
            name: "detailList",
            style: {
              zIndex: 0,
              Alignment: "center",
              FontSize: 9,
              FontColor: "#000000",
              BorderColor: "#000000",
              AutoHeight: true,
              BottomMargin: 0,
            },
            uuid: "8f1ee2cff3",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 435,
            title: "仓库管理员",
            value: "仓库管理员： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a248",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 145,
            top: 435,
            title: "仓库管理员",
            value: "{仓库管理员} ",
            defaultValue: "仓库管理员",
            name: "warehouseManagerResult",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a219",
          },
        ],
        default: true,
      },
      widgets: [
        {
          type: "braid-txt",
          isEdit: false,
          title: "自定义文本",
          value: "自定义文本",
          defaultValue: "自定义文本",
        },
        {
          type: "braid-txt",
          isEdit: true,
          title: "公司名称",
          value: "绍兴上虞新越医疗器械有限公司样品领用单",
          //   value: "{公司名称}",
          defaultValue: "绍兴上虞新越医疗器械有限公司样品领用单",
          name: "companyName",
          width: "450",
          top: "18",
          left: "160",
          style: {
            FontSize: 15,
            Bold: true,
            Alignment: "center",
          },
        },
        {
          type: "bar-code",
          title: "订单编号（条码）",
          value: "{orderNumber}",
          defaultValue: "1234567890",
          name: "orderNumber",
          lodopStyle: { QRCodeVersion: "1", QRCodeErrorLevel: "L" },
        },
        {
          type: "braid-html",
          title: "分页",
          value: "{第##页/共##页}",
          defaultValue:
            "<font ><span tdata='pageNO'>第##页</span>/<span tdata='pageCount'>共##页</span></font>",
        },
        {
          type: "braid-image",
          title: "logo",
          value:
            "http://192.168.7.229/syy/file/files/view/5f9fb42dabebb4049ffbd019",
        },
        {
          type: "braid-table",
          title: "产品明细",
          name: "details",
          value: "{details}",
          width: "635",
          top: "116",
          defaultValue: [
            {
              productName: "苹果ipone11pro",
              skuName: "iphone11pro256g",
              specModel: "165L",
              quantity: 3,
              price: "12.00",
            },
            {
              productName: "苹果ipone11pro",
              skuName: "iphone11pro124g",
              specModel: "165L",
              quantity: 3,
              price: "12.00",
            },
          ],
          columnsAttr: [
            {
              title: "产品编号",
              value: "{产品编号}",
              name: "productNo",
            },
            {
              title: "样品名称",
              value: "{样品名称}",
              name: "sampleName",
            },
            {
              title: "规格型号",
              value: "{规格型号}",
              name: "spec",
            },
            {
              title: "计划领用数量",
              value: "{计划领用数量}",
              name: "quantity",
            },
            {
              title: "备注",
              value: "{备注}",
              name: "remarks",
            },
          ],
        },
      ],
      licenseInfo: "",
      valueData: {},
    };
  },
  methods: {
    handleSave() {
      this.$lodop.preview(this.value, [this.valueData]);
    },
    selectSampleRequisitionInfoById(id) {
      selectSampleRequisitionInfo(id).then((res) => {
        res.warehouseManagerResult =
          res.warehouseManagerResult == 1 ? "通过" : "不通过";
        res.newCustomer = res.newCustomer == 1 ? "是" : "否";
        res.applyDate = res.applyDate.substring(0, 10);
        this.valueData = res;
      });
    },
  },

  mounted() {
    let id = this.$route.query.id;
    this.selectSampleRequisitionInfoById(id);
  },
};
</script>
<style lang="css">
.printBody .kr-designer-tool_bar button[type="button"]:last-child {
  display: none;
}
.printBody .kr-designer-tool_bar button[type="button"]:first-child {
  font-size: 35px;
}
</style>
<style lang="css" scoped>
@import url("./kr-print-designer2.css");
</style>
